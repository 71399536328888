export default {
  formTitle: "Sparen Sie Geld durch Umschuldung",
  formSubTitle: "Wir unterstützen Sie dabei!",
  heading: "Schnelle Auszahlung",
  subHeading: "100% Kostenlos und Unverbindlich",
  notice: `Kredite können Sie schnell durch zu hohe Zinsen viel Geld kosten. Wir helfen Ihnen, Ihre bestehenden Kredite zu günstigen Konditionen umzuschulden. Zahlen Sie nicht mehr als Sie müssen und handeln Sie jetzt!

  Sofort24Kredit vermittelt Ihnen günstige Kredite um Ihre bestehenden Schulden zu begleichen. Beantragen Sie schnell eine Umschuldung, damit Ihnen Probleme und teure Zinszahlungen in Zukunft erspart bleiben. Fassen Sie Ihre Schulden zusammen und behalten Sie stets den Überblick. Unsere Experten beraten Sie gerne.`,
  itemsTitle: "Vorteile einer Umschuldung zusammengefasst:",
  items: [
    "100% kostenlose und unverbindliche Antragstellung (Wie es der Rechtslage entspricht)",
    "Unbürokratisch, schnell und auf Sie abgestimmt",
    " Keine Zweckbindung - machen Sie mit Ihrem Geld was Sie wollen!",
    "Unsere Experten beraten Sie jederzeit kompetent und professionell",
    "Bank- oder Beratertermine entfallen für Sie",
    "Garantierte sofortige Antragsannahme",
    "Wir passen Laufzeiten an Ihre persönliche Situation an",
    "Abwicklung auf dem Postweg - sparen Sie sich lästige Gänge zu Beratern oder Banken",
    "Zusage erfolgt sofort online!",
    "Auszahlung Ihres Wunschbetrages erfolgt innerhalb von max. 48 Stunden",
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
